import { useMutation } from '@apollo/client';
import Config from 'app/config';
import { CheckboxLoginOverrides } from 'app/themes';
import { styled, useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { LocaleContext } from 'baseui/esm/locale/index';
import { FormControl } from 'baseui/form-control';
import { ArrowRight } from 'baseui/icon';
import { Input, SIZE } from 'baseui/input';
import { toaster } from 'baseui/toast';
import { validate as validateEmail } from 'email-validator';
import gql from 'graphql-tag';
import React, { ChangeEvent, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

declare var grecaptcha: any;
// const Field = Form.Item;

export interface FormError {
  message: string;
}

const USER_CHECK_MUTATION = gql`
  mutation UserCheck($email: String!) {
    userCheck(email: $email) {
      link
      successful
      failedAttempts
      errors {
        message
        path
      }
    }
  }
`;

const LoginForm = props => {
  const { onSubmit, errors, onSubmitToken } = props;
  const locale = (useContext(LocaleContext) as any).login;
  const [css, theme] = useStyletron();
  const recaptchaRef = useRef<HTMLDivElement>();
  const [step, setStep] = useState(1);
  const popupHandler = useRef<Window>(null);
  const [showCaptcha, setShowCaptcha] = useState(props.showCaptcha);
  const [userCheck, userCheckResult] = useMutation(USER_CHECK_MUTATION);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaWidgetRef = useRef(null);
  const [isValid, setIsValid] = useState({email: false, password: false});
  const [isVisited, setIsVisited] = useState({ email: false, password: false });
  const [isLoading, setIsLoading] = useState(false);
  const shouldShowError = useCallback((key) => !isValid[key] && isVisited[key], [isValid, isVisited]);
  const disabledButton = (step > 1 && (!email || !password || (showCaptcha && !captchaToken))) || step === 1 && !email;

  const captchaErrorCallback = () => {
    setCaptchaToken(null);
    alert(locale.errorValidation)
  }

  useEffect(() => {
    if (showCaptcha && recaptchaRef.current) {
      grecaptcha.enterprise.ready(function () {
        if (captchaWidgetRef.current === null) {
          captchaWidgetRef.current = grecaptcha.enterprise.render(recaptchaRef.current, {
            sitekey: Config.RECAPTCHA_SITEKEY,
            action: 'LOGIN',
            callback: setCaptchaToken,
            'expired-callback': () => setCaptchaToken(null),
            'error-callback': captchaErrorCallback
          })
        }
      });
    }
  }, [showCaptcha, recaptchaRef.current]);

  useEffect(() => {
    if (errors?.length > 0) {
      setIsLoading(false);
      if (captchaWidgetRef.current !== null) {
        grecaptcha.enterprise.reset(captchaWidgetRef.current);
      }
    }
  }, [errors]);

  useLayoutEffect(() => {
    if (step > 1) {
      passwordRef.current.focus();
    }
  }, [step]);

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setIsValid(old => ({ ...old, email: validateEmail(value) }));
    setEmail(value);
  };

  const onChangePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setIsValid(old => ({ ...old, password: !!value }));
    setPassword(value);
  };

  const checkBeforeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    if (step === 1) {
      // try to get info about this user
      userCheck({variables: {email: email}}).then(({ data: {userCheck} }) => {
        console.log(userCheck);
        if (!userCheck.successful) {
          if (userCheck.failedAttempts > 0)
            setShowCaptcha(() => true);
          setIsLoading(() => false);
          setStep(() => 2);
          // console.log(passwordRef.current);
        } else {
          const func = function (e) {
            if (e.data.type === 'token') {
              console.log(e);
              if (e.data.error) {
                setIsLoading(() => false);
                toaster.negative(<>Usuário inválido</>);
              } else {
                onSubmitToken({ token: e.data.token });
              }
              window.onmessage = null;
            }
          };
          window.onmessage = func;
          openPopup(userCheck.link);
        }
      }).catch((error) => {
        setIsLoading(() => false);
      });
    } else {
      // console.log((e.target as HTMLFormElement));
      onSubmit({ email, password, rememberMe, captchaToken });
    }
  }

  const openPopup = (link) => {
    popupHandler.current = window.open(
      link,
      'Maply SSO Login',
      'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'
    );

    popupHandler.current.window.focus();
  }

  return (
    <Form onSubmit={checkBeforeSubmit}>
      <p className={css({
        textAlign: 'center',
        marginBottom: '16px',
        lineHeight: '28px',
        fontSize: '20px'
      })}>{locale.accountAccess}</p>
      <FormControl
        label={() => locale.enterEmail}
        error={shouldShowError('email') ? locale.formatEmail: null}
      >
        <Input
          type="email"
          placeholder={locale.enterEmail}
          autoFocus
          value={email}
          onChange={onChangeEmail}
          onBlur={(e) => e.target.value.trim() && setIsVisited(old => ({ ...old, email: true })) }
          error={shouldShowError('email')}
          overrides={{
          Root: {
            style: {
              backgroundColor: 'transparent'
            }
          },
          InputContainer: {
            style: {
              backgroundColor: 'transparent'
            }
          }
        }}/>
      </FormControl>
      {step > 1 && <FormControl
        label={() => locale.enterPassword}
        error={shouldShowError('password') ? locale.formatPassword: null}
      >
        <Input
          type="password"
          placeholder={locale.enterPassword}
          onBlur={() => setIsVisited(old => ({ ...old, password: true }))}
          onChange={onChangePassword}
          error={shouldShowError('password')}
          inputRef={passwordRef}
          overrides={{
          Root: {
            style: {
              backgroundColor: 'transparent'
            }
          },
          InputContainer: {
            style: {
              backgroundColor: 'transparent'
            }
          }
        }}/>
      </FormControl>}
      {step > 1 && <FormControl>
        <Checkbox
          overrides={CheckboxLoginOverrides}
          checked={rememberMe}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRememberMe(e.target.checked)}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          {locale.remember}
        </Checkbox>
      </FormControl>}
      {showCaptcha && <div id="recaptcha" ref={recaptchaRef} className={css({
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
      })}></div>}
      <Button
        type="submit"
        size={SIZE.large}
        endEnhancer={() => <ArrowRight size={30} />}
        disabled={disabledButton}
        isLoading={isLoading}
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              position: 'relative',
              fontSize: '18px',
              lineHeight: '24px',
              fontWeight: 500
            }
          },
          EndEnhancer: {
            style: {
              position: 'absolute',
              right: '20px'
            }
          }
        }}
      >
        {step > 1 ? locale.enter : locale.continue}
      </Button>
    </Form>
  );
};

const Form = styled('form', ({$theme}) => {
  return {
    width: '360px'
  }
});

export default LoginForm;
